<template>
  
  <div>

    <confirma ref="conf" @respondeu="(r) => { this.continuarConfirmar(r) }">
      <template v-slot:texto><span v-html="mensagemEvento"></span></template>
    </confirma>

    <b-container v-show="confirmarEdicao">
      <div class="container-fluid">
        <b-row class="my-1 mx-0">
          <div class="col-md-12">
            {{ mensagemEvento }}
          </div>
        </b-row>
        <b-row class="my-1  mx-0">
          <div class="col-md-12">
            <button type="button" style="margin-left: 4px" v-if="event && event.id > 0" size="sm"
              class="btn btn-warning btn-fill float-right" @click="registrarEvento">
              Não
            </button>
            <button type="button" v-if="event && event.id > 0" size="sm" class="btn btn-success btn-fill float-right"
              @click="replicarEvento">
              Sim
            </button>
          </div>
        </b-row>
      </div>
    </b-container>

    <select-profissional :unico="true" ref="selectProfissional"
      @selecionou="(r) => { selecionarProfissional(r.profissional) }"
      @cadastrou="(r) => { selecionarProfissional(r.profissional) }" />

    <!-- editar-dados-profissional ref="editarDadosProfissional" @executou="(r) => { confirmarDadosProfissional(r) }" />-->

    <b-modal header-bg-variant="header-modal-js" header-text-variant="light" footer-bg-variant="light" body-class="p-1 body-event-plantao" header-class="px-4 py-1" footer-class="p-1"
    ref="modal-event-modal" id="modal-event-modal" size="xl" no-close-on-backdrop hide-header-close modal-class="moda-event-plantao">

      <!-- div class="container fluid" v-show="!iniciou">
        <div class="text-center">
          <b-spinner variant="primary" small type="grow"></b-spinner>
          Carregando...
        </div>
      </div -->

      <template #modal-title>
        <div style="font-size:21px; font-weight: 600;" class="m-2">          
          <b-icon :icon="event && event.id < 0 ? 'calendar-plus' : 'calendar-check'" class="mr-3"></b-icon>{{ event && event.id < 0 ? 'Criar Plantão' : 'Alterar Plantão' }}
        </div>
      </template>

      <loading :show="show">

        <template slot="message">
        {{ this.textoLoad }}
        </template>

      <div class="detalhe-agenda m-2" >

        <b-tabs content-class="mt-0" class="tab-detalhe-agenda">

          <b-tab title="Dados">

            <div class="edit-agenda">

              <form>

                <b-row class="my-2 mx-0">
                  <div class="col-md-5">
                    <b-form-group class="mb-0" label="Local do plantão" label-for="input-local">
                      <filtro-local @showOverlay="(status) => this.$emit('showOverlay', status)"
                        @update="idLocal = $event ? $event.id : null; alterarLocal(idLocal)" :multiple="false" id="input-local" ref="filtroLocal" :hideShowLoading="!iniciou" :fontSize="0.7" v-if="hasPermission('AGENDA', 'EDIT_LOCAL', 'E')"></filtro-local>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_LOCAL', 'L')"> {{ this.nomeLocal }} </span>
                    </b-form-group>
                  </div>

                  <div class="col-md-3">
                    <b-form-group class="mb-0" label="Tipo de serviço" label-for="input-tipo">                      
                      <b-row class="m-0 p-0">
                        <div class="col-11 m-0 p-0">
                          <b-form-select v-if="hasPermission('AGENDA', 'EDIT_TIPO', 'E')" id="input-tipo"
                            :disabled="perfilLogado === 'PROF'" v-model="idTipo" value-field="id"
                            @change="alterarTipo(idTipo, true)" text-field="descricao" :options="itensTipo" size="sm">
                            <template #first>
                              <b-form-select-option :value="null">-- Selecione o Tipo --</b-form-select-option>
                            </template>
                          </b-form-select>
                          <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_TIPO', 'L')"> {{ this.dscTipo }} </span>
                        </div>							
                        <div class="col-1 m-0 p-0">
                          <b-button v-if="hasPermission('AGENDA', 'EDIT_TIPO', 'E') && perfilLogado !== 'PROF'"
                            size="sm" class="ml-1 btn btn-fill btn-busca" variant="success" @click="recarregarListaTipo">
                              <b-icon icon="arrow-repeat" aria-hidden="true"></b-icon>
                          </b-button>
                        </div>
                      </b-row>
                    </b-form-group>
                  </div>
                </b-row>

                <b-row class="my-2 mx-0" v-if="permiteRecorrencia">
                  <div class="col-md-8 my-0 py-0">
                    <b-form-radio-group id="radio-group-modo" v-model="modoLancamento" name="radio-group-modo"
                      @change="alteraModoLancamento">
                      <b-form-radio value="individual">Individual</b-form-radio>
                      <b-form-radio value="replicar">Replicar</b-form-radio>
                      <b-form-radio value="datas">Datas</b-form-radio>
                    </b-form-radio-group>
                  </div>
                </b-row>

                <b-row class="mx-0 my-2 py-0 d-flex align-items-center">
                  <div class="col-md-2">
                    <b-form-group class="mb-0" label="Data" label-for="input-data-inicio">
                      <b-form-datepicker id="input-data-inicio" v-if="hasPermission('AGENDA', 'EDIT_DATA', 'E')"
                        v-model="dtAgenda" size="sm" placeholder="Data início" today-button reset-button close-button
                        today-button-variant="primary" reset-button-variant="danger" close-button-variant="success"
                        label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
                        label-reset-button="Limpar" label-close-button="Fechar"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                        @input="adicionarData($event)">
                      </b-form-datepicker>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_DATA', 'L')"> {{
                        strDateFormat(this.dtAgenda, 'dd/MM/yyyy')
                      }} </span>
                    </b-form-group>
                  </div>

                  <div class="col-md-1">
                    <b-form-group class="mb-0" label="Duração" label-for="input-duracao">
                      <b-form-input v-if="hasPermission('AGENDA', 'EDIT_DURA', 'E')" id="input-duracao" v-mask="'##:##'"
                        label="Duração" placeholder="hh:mm" v-model="duracao" size="sm"
                        :readonly="perfilLogado === 'PROF' ? true : false"></b-form-input>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_DURA', 'L')"> {{ this.duracao }} </span>
                    </b-form-group>
                  </div>
                  <div class="col-md-1">
                    <b-form-group class="mb-0" label="Início" label-for="input-horario">
                      <b-form-input v-if="hasPermission('AGENDA', 'EDIT_HORA', 'E')" id="input-horario"
                        :disabled="perfilLogado === 'PROF'" v-mask="'##:##'" label="Horário Início" placeholder="hh:mm"
                        v-model="horarioDe" size="sm">
                      </b-form-input>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_HORA', 'L')"> {{ this.horarioDe }} </span>
                    </b-form-group>
                  </div>

                  <div class="col-md-1" v-if="!hasPermissionWith('AGENDA', 'EDIT_QUANTIDADE', 'SP')">
                    <b-form-group class="mb-0" label="Quant." label-for="input-quantidade">
                      <b-form-input v-if="hasPermission('AGENDA', 'EDIT_QUANTIDADE', 'E')" id="input-quantidade"
                        v-money="decimal" label="Quantidade" v-model="quantidadeEscala" size="sm">
                      </b-form-input>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_QUANTIDADE', 'L')"> {{ this.quantidadeEscala }}</span>
                    </b-form-group>
                  </div>

                  <div class="col-md-2">
                    <b-form-group v-if="this.tipoApuracao" class="mb-0" label="Tipo de apuração" label-for="input-tipo-apuracao">
                      <span class="label"> {{ this.tipoApuracao }} </span>
                    </b-form-group>
                  </div>

                  <div class="col-md-2" v-show="modoLancamento === 'replicar'">
                    <b-form-group class="mb-0" label="Até" label-for="input-data-fim">
                      <b-form-datepicker v-model="end" size="sm" placeholder="Data fim" today-button reset-button
                        close-button today-button-variant="primary" reset-button-variant="danger"
                        close-button-variant="success" label-no-date-selected="Nenhuma data selecionada"
                        label-today-button="Hoje" label-reset-button="Limpar" label-close-button="Fechar"
                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                      </b-form-datepicker>
                    </b-form-group>
                  </div>

                  <div class="col-md-2" v-show="modoLancamento === 'replicar' && idTipo !== null">
                    <b-button-group size="sm" class="pt-3">
                      <b-button pill class="btn-warning" style="margin-left: 4px" v-for="(btn, idx) in diaSemana"
                        :key="idx" :pressed.sync="btn.selecionado" variant="primary">
                        {{ btn.abreviacao }}
                      </b-button>
                    </b-button-group>
                  </div>

                </b-row>

                <b-row class="mx-0" v-show="modoLancamento === 'datas'">
                  <div class="col-md-8 my-0 py-0">
                    <b-form-tags v-model="dtAgendas" no-outer-focus placeholder="" tag-variant="primary" class="m-0 p-0">
                      <template v-slot="{ tags, tagVariant, removeTag }">
                        <div class="d-inline-block lista-data-livre m-1">
                          <b-form-tag v-for="tag in tags" @remove="removeTag(tag)" :key="tag" :title="tag"
                            :variant="tagVariant" class="data-livre">{{ strDateFormat(tag, 'dd/MM/yyyy')
                            }}</b-form-tag>
                        </div>
                      </template>
                    </b-form-tags>
                  </div>
                </b-row>

                <b-row class="my-2 mx-0">
                  <div class="col-md-6 my-0 py-0">
                    <b-row class="m-0 p-0" v-if="!maisDeUmProfissional">                      
                      <div class="col-9 m-0 p-0">
                        <b-form-group class="mb-0" label="Profissional alocado (fixo)" label-for="input-profissional-alocado">
                          <b-row class="m-0 p-0" v-if="hasPermission('AGENDA', 'EDIT_PROF', 'E')">
                            <div class="col-11 m-0 p-0">
                              <v-select id="input-profissional-alocado" :multiple="isListaProfissionalAlocado"
                                :disabled="perfilLogado === 'PROF'" v-model="idProfissionalEfetivo"
                                :options="itensProfissionalPlantao" :reduce="prof => prof.id" label="nome" auto
                                placeholder="Selecione o profissional alocado"
                                @input="alterarProfissionalAlocado(idProfissionalEfetivo)" :filterBy="filterBy">
                                <template #no-options>
                                  Nenhum profissional encontrado
                                </template>
                              </v-select>
                            </div>
                            <div class="col-1 m-0 p-0">
                              <b-button v-if="hasPermission('AGENDA', 'PESQUISAR_PROFISSIONAL', 'E')" size="sm"
                                class="ml-1 btn btn-fill btn-busca" variant="primary"
                                @click="abrirSelecionaProfissional('alocado')">
                                <b-icon icon="search" aria-hidden="true"></b-icon>
                              </b-button>
                            </div>
                          </b-row>

                          <b-row class="m-0 pt-1" v-else-if="hasPermission('AGENDA', 'EDIT_PROF', 'L')">
                            <b-col cols="12 pl-1">
                              <span class="label">{{ this.nomeProfissional }}</span>
                              <span class="label" v-if="this.telefoneProfissional && this.telefoneProfissional !== ''"> 
                                {{ ' - Telefone: ' + this.telefoneProfissional }} </span>
                            </b-col>
                          </b-row>

                          <b-row class="m-0 pt-1" v-if="isPncAlocado">
                            <div class="col-11 m-0 p-0">
                              <span class="label" v-if="idProfissionalAlocadoPnc" id="input-profissional-alocado-pnc">{{
                                nomeProfissionalAlocadoPnc }}</span>
                            </div>
                          </b-row>

                        </b-form-group>
                      </div>

                      <div class="col-12 col-md-3 m-0 py-0" v-if="!maisDeUmProfissional && !maisDeUmProfissionalAlocado">
                        <b-form-group class="mb-0" label="Troca?" label-for="input-troca">
                          <b-form-select v-if="hasPermission('AGENDA', 'EDIT_MOTIVO_TROCA', 'E')" id="input-troca"
                            :disabled="perfilLogado === 'PROF'" v-model="idMotivoTroca" value-field="id"
                            text-field="descricao" :options="itensMotivoTroca" size="sm">
                            <template #first>
                              <b-form-select-option :value="null">Motivo troca</b-form-select-option>
                            </template>
                          </b-form-select>
                          <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_MOTIVO_TROCA', 'L')"> {{ this.dscMotivoTroca ? this.dscMotivoTroca : 'Não'}}
                          </span>
                        </b-form-group>
                      </div>

                    </b-row>

                    <b-row class="m-0 px-0 pt-1" v-if="!maisDeUmProfissionalAlocado">
                      <div class="col-9 m-0 p-0">
                        <b-form-group class="mb-0" label="Profissional do plantão (quem executou)" label-for="input-profissional">

                          <b-row class="m-0 p-0" v-if="hasPermission('AGENDA', 'EDIT_PROF', 'E')">
                            <div class="col-11 m-0 p-0">
                              <v-select id="input-profissional"
                                :disabled="perfilLogado === 'PROF' || !isProfissionalPlantaoLiberado"
                                v-model="idProfissional" :multiple="isListaProfissional"
                                :options="itensProfissionalPlantao" :reduce="prof => prof.id" label="nome" auto
                                placeholder="Selecione o profissional" @input="alterarProfissionalPlantao(idProfissional)"
                                :filterBy="filterBy">
                                <template #no-options>
                                  Nenhum profissional encontrado
                                </template>
                              </v-select>
                            </div>
                            <div class="col-1 m-0 p-0">
                              <b-button v-if="hasPermission('AGENDA', 'PESQUISAR_PROFISSIONAL', 'E')" variant="primary"
                                size="sm" :disabled="!isProfissionalPlantaoLiberado" class="ml-1 btn btn-fill btn-busca"
                                @click="abrirSelecionaProfissional('plantao')">
                                <b-icon icon="search" aria-hidden="true"></b-icon>
                              </b-button>
                            </div>
                          </b-row>

                          <b-row class="m-0 pt-1" v-else-if="hasPermission('AGENDA', 'EDIT_PROF', 'L')">
                            <b-col cols="12 pl-1">
                              <span class="label">{{ this.nomeProfissionalEfetivo }}</span>
                            </b-col>
                          </b-row>

                          <b-row class="m-0 pt-1" v-if="isPnc">
                            <div class="col-11 m-0 p-0">
                              <span class="label" v-if="idProfissionalPnc" id="input-profissional-pnc">{{ nomeProfissionalPnc
                              }}</span>
                            </div>
                          </b-row>

                        </b-form-group>
                      </div>
                    </b-row>
                  </div>

                  <div class="col-md-2 m-0 p-0">

                    <b-row class="m-0 p-0">
                      <div class="col-12 m-0 py-0 pl-0">
                        <b-form-group class="mb-0" label="Situação" label-for="input-situacao">
                          <b-form-select v-if="hasPermission('AGENDA', 'EDIT_SIT', 'E')" id="input-situacao"
                            :disabled="perfilLogado === 'PROF'" v-model="idSituacao" value-field="id"
                            text-field="descricao" @change="alterarSituacao(idSituacao, true)" :options="itensSituacao"
                            size="sm">
                            <template #first>
                              <b-form-select-option :value="null">-- Selecione a situação --</b-form-select-option>
                            </template>
                          </b-form-select>
                          <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_SIT', 'L')"> {{ this.dscSituacao }} </span>
                        </b-form-group>
                      </div>
                    </b-row>

                    <b-row class="mx-0 mb-0 mt-1 p-0">
                      <div class="col-12 mx-0 px-0 pb-0 mb-0">
                        <b-form-group class="my-0 py-0" label-cols="3" label="Esta vaga é fixa?" v-if="mostrarFixo == 'S'">
                          <b-form-radio-group v-model="fixo" buttons button-variant="primary">
                            <b-form-radio value="S" class="btn-radio" >Sim</b-form-radio>
                            <b-form-radio value="N" class="ml-1 btn-radio">Não</b-form-radio>
                          </b-form-radio-group>
                        </b-form-group>
                      </div>
                    </b-row>
                  </div>
                </b-row>

                <b-row class="mx-0" v-if="mostrarProfissionalEfetivo">
                  <div class="col-md-7">
                    <b-form-group class="mb-0" label="Profissional efetivo" label-for="input-profissional-efetivo">
                      <b-form-input id="input-profissional-efetivo" :disabled="true" label="Profissional Efetivo"
                        v-model="nomeProfissionalEfetivo" size="sm">
                      </b-form-input>
                    </b-form-group>
                  </div>
                </b-row>

                <b-row class="my-2 py-0 mx-0">

                  <div class="col-1">
                    <b-form-group label="Adiantado" label-for="input-adiantamento" class="my-0 py-0"
                      v-if="!hasPermissionWith('AGENDA', 'EDIT_ADIANTAMENTO', 'SP')">
                      <b-input-group id="input-adiantamento" size="sm" class="my-0 py-0">

                        <b-input-group-prepend is-text button-variant="warning"
                          v-if="hasPermission('AGENDA', 'EDIT_ADIANTAMENTO', 'E')">
                          <b-form-checkbox v-model="pagamentoAvista" name="check-button" switch unchecked-value="N"
                            class="my-0 py-0" value="S"></b-form-checkbox>
                        </b-input-group-prepend>

                        <b-input-group-append>
                          <b-input-group-text class="py-0"> {{ this.pagamentoAvista === 'S' ? 'Sim' : 'Não' }}
                          </b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </div>

                  <div class="col-md-1">
                    <b-form-group label="Vl. unit." label-for="input-valor-compra"
                      v-if="!hasPermissionWith('AGENDA', 'EDIT_VALOR', 'SP')">
                      <b-form-input v-model="valorCompra" id="input-valor-compra" style="font-size: 0.7rem;"
                        :readonly="hasPermission('AGENDA', 'EDIT_VALOR', 'L')" v-money="money" placeholder="Valor"
                        size="sm" @click="$event.target.select()"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-1">
                    <b-form-group label="Total" label-for="input-valor-total-compra"
                      v-if="!hasPermissionWith('AGENDA', 'EDIT_VALOR', 'SP')">
                      <b-form-input v-model="valorTotalCompra1" id="input-valor-total-compra" style="font-size: 0.6rem;"
                        :readonly="hasPermission('AGENDA', 'EDIT_VALOR', 'L')" v-money="money" placeholder="Valor"
                        size="sm" @click="$event.target.select()"></b-form-input>
                    </b-form-group>
                  </div>

                  <div class="col-md-1">
                    <b-form-group label="Complemento" label-for="input-valor-complementar"
                      v-if="!hasPermissionWith('AGENDA', 'EDIT_VCOMPL', 'SP')">
                      <b-form-input v-model="valorCompl" id="input-valor-complementar"
                        v-if="hasPermission('AGENDA', 'EDIT_VCOMPL', 'E')" v-money="money" placeholder="Valor compl."
                        size="sm" @click="$event.target.select()"></b-form-input>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_VCOMPL', 'L')"> {{
                        valorCompl.toLocaleString('pt-BR', {
                          style: 'currency', currency: 'BRL', maximumFractionDigits:
                            2
                        })
                      }} </span>
                    </b-form-group>
                  </div>

                  <div class="col-2">
                    <b-form-group class="mb-0" label="Observação restrita" label-for="input-obs"
                      v-if="!hasPermissionWith('AGENDA', 'EDIT_OBS_RESTRITA', 'SP')">
                      <b-form-textarea id="textarea-obs" v-model="obs" placeholder="Informe a observação..." max-rows="3"
                        size="sm" v-if="hasPermission('AGENDA', 'EDIT_OBS_RESTRITA', 'E')">
                      </b-form-textarea>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_OBS_RESTRITA', 'L')"> {{ this.obs }} </span>
                    </b-form-group>
                  </div>

                  <div class="col-2">
                    <b-form-group class="mb-0" label="Observação pública" label-for="input-obs-publica"
                      v-if="!hasPermissionWith('AGENDA', 'EDIT_OBS_PUBLICA', 'SP')">
                      <b-form-textarea id="textarea-obs-publica" v-model="obsPublica"
                        placeholder="Observação pública que será visualizada inclusive pelos profissionais..."
                        max-rows="3" size="sm" v-if="hasPermission('AGENDA', 'EDIT_OBS_PUBLICA', 'E')">
                      </b-form-textarea>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_OBS_PUBLICA', 'L')"> {{ this.obs }} </span>
                    </b-form-group>
                  </div>

                </b-row>

                <b-row class="my-0 py-0 mx-0" v-if="pedirAutorizacao">
                  <div class="col-3" v-if="itensUsuariosAprovadores && itensUsuariosAprovadores.length">
                    <b-form-group class="mb-0" :label="labelAutorizacao" label-for="input-responsavel-aprovacao">

                      <b-form-select id="input-usuario_aprovador" v-model="idResponsavelAprovacao"
                        :options="itensUsuariosAprovadores" text-field="nome" value-field="id" size="sm">
                      </b-form-select>

                    </b-form-group>
                  </div>
                  <div class="col-3" v-if="itensResponsavelPagamento && itensResponsavelPagamento.length">
                    <b-form-group class="mb-0" label="Resp. pelo pagamento" label-for="input-responsavel-pagamento">
                      <b-form-select id="input-responsavel-pagamento" v-model="idResponsavelPagamento"
                        :options="itensResponsavelPagamento" text-field="descricao" value-field="id" size="sm">
                      </b-form-select>
                    </b-form-group>
                  </div>
                </b-row>

              </form>
            </div>
          </b-tab>

          <b-tab v-if="!hasPermissionWith('AGENDA', 'HISTORICO', 'SP')" title="Histórico" lazy @click="buscarHistorico">

            <div class="mt-2 historico-event-plantao">

              <b-table :fixed="true" :bordered="true" :items="historicos" :fields="fields" responsive="true"
              tbody-tr-class="grid-tr" class="grid-js"
                head-variant="light" sticky-header>
                <template v-for="field in fields" v-slot:[`cell(${field.key})`]="{ value, item, field }">
                  <template v-if="field.type !== undefined && field.type === 'date'">{{ strDateFormat(value,
                    field.format)
                  }}</template>
                  <template v-else>{{ value }}</template>
                </template>
              </b-table>

              <div class="mx-3" v-if="hasPermission('AGENDA', 'EDIT_HIST', 'E')">
                <b-row class="my-1 mx-0">
                  <div class="col-md-7 label">Novo histórico</div>
                </b-row>
                <b-row class="mx-0">
                  <b-form-textarea id="textarea" v-model="historico" placeholder="Informe o novo histórico..." rows="1"
                    max-rows="3" size="sm"></b-form-textarea>
                </b-row>
              </div>
            </div>
          </b-tab>
          <b-tab title="Outras info.">
            <div class="edit-outras-info">
              <form>

                <b-row class="my-2 mx-0">
                  <div class="col-md-1">
                    <b-form-group class="mb-0" label="Plantão previsto?" label-for="input-planejamento-plantao">
                      <b-form-select v-if="hasPermission('AGENDA', 'EDIT_MODO_PLANEJAMENTO', 'E')" id="select-modo-planejamento" v-model="modoPlanejamento" :options="itensPlanejamento">
                      </b-form-select>
                      <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_MODO_PLANEJAMENTO', 'L')"> {{ this.itensPlanejamento.find(p => p.value === this.modoPlanejamento).text }} </span>
                    </b-form-group>
                  </div>
                </b-row>

                <b-row class="my-2 mx-0">
                    <div class="col-md-1">
                      <b-form-group class="mb-0" label="Duração prevista" label-for="input-duracao">
                        <b-form-input v-if="hasPermission('AGENDA', 'EDIT_DURA', 'E')" id="input-duracao-prevista" v-mask="'##:##'"
                          label="Duração prevista" placeholder="hh:mm" v-model="duracaoPrevista" size="sm" :readonly="perfilLogado === 'PROF' ? true : false"></b-form-input>
                        <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_DURA', 'L')"> {{ this.duracaoPrevista }} </span>
                      </b-form-group>
                    </div>                  

                    <div class="col-md-1" v-if="!hasPermissionWith('AGENDA', 'EDIT_QUANTIDADE', 'SP')">
                      <b-form-group class="mb-0" label="Quant. prevista" label-for="input-quantidade-prevista">
                        <b-form-input v-if="hasPermission('AGENDA', 'EDIT_QUANTIDADE', 'E')" id="input-quantidade"
                          v-money="decimal" label="Quantidade" v-model="quantidadePrevista" size="sm">
                        </b-form-input>
                        <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_QUANTIDADE', 'L')"> {{ this.quantidadePrevista }}</span>
                      </b-form-group>
                    </div>

                    <div class="col-md-2">
                      <b-form-group class="mb-0" label="Competência fat." label-for="input-competencia">
                        <b-form-datepicker id="input-competencia" v-if="hasPermission('AGENDA', 'EDIT_DATA', 'E')"
                          v-model="competencia" size="sm" placeholder="Competência" today-button reset-button close-button
                          today-button-variant="primary" reset-button-variant="danger" close-button-variant="success"
                          label-no-date-selected="Nenhuma data selecionada" label-today-button="Hoje"
                          label-reset-button="Limpar" label-close-button="Fechar"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }">
                        </b-form-datepicker>
                        <span class="label" v-else-if="hasPermission('AGENDA', 'EDIT_DATA', 'L')"> {{
                          this.competencia ? strDateFormat(this.competencia, 'dd/MM/yyyy') : ''
                        }} </span>
                      </b-form-group>
                    </div>

                  </b-row>

                  <b-row class="my-2 mx-0">
                  <div class="col-md-3 my-0 py-0">
                    <b-row class="m-0 p-0" >
                      <div class="col-12 m-0 p-0">
                        <b-form-group class="mb-0" label="Favorecido" label-for="input-profissional-favorecido">
                          <b-row class="m-0 p-0" v-if="hasPermission('AGENDA', 'EDIT_PROF_FAV', 'E')">
                            <div class="col-11 m-0 p-0">
                              <b-form-select v-if="hasPermission('AGENDA', 'EDIT_PROF_FAV', 'E')" id="input-profissional-favorecido"
                                :disabled="perfilLogado === 'PROF'" v-model="idProfissionalFavorecido" value-field="id"
                                @change="alterarProfissionalFavorecido(idProfissionalFavorecido)" text-field="nome" :options="itensProfissionalPlantao" size="sm">
                                <template #first>
                                  <b-form-select-option :value="null">-- Quem irá receber o pagamento? --</b-form-select-option>
                                </template>
                              </b-form-select>
                            </div>
                            <div class="col-1 m-0 p-0">
                              <b-button v-if="hasPermission('AGENDA', 'PESQUISAR_PROFISSIONAL', 'E')" size="sm"
                                class="ml-1 btn btn-fill btn-busca" variant="primary"
                                @click="abrirSelecionaProfissional('favorecido')">
                                <b-icon icon="search" aria-hidden="true"></b-icon>
                              </b-button>
                            </div>
                          </b-row>
                          <b-row class="m-0 pt-1" v-else-if="hasPermission('AGENDA', 'EDIT_PROF', 'L')">
                            <b-col cols="12 pl-1">
                              <span class="label">{{ this.nomeProfissionalFavorecido }}</span>                              
                            </b-col>
                          </b-row>
                        </b-form-group>
                      </div>                      
                    </b-row>
                  </div>
                  </b-row>

              </form>
            </div>
                
          </b-tab>
        </b-tabs>
      </div>
      </loading>

      <template #modal-footer>
        
          <div class="col-md-12 m-0 px-0 pt-2 pb-0 footer-event-plantao">

            <b-button size="md" class="btn btn-fill float-right ml-1" variant="warning" @click="fechar">
              Fechar
            </b-button>

            <b-button v-show="perfilLogado !== 'PROF'" variant="danger"
              v-if="event.id > 0 && hasPermission('AGENDA', 'EXCLUIR', 'E')" size="md" class="btn-fill float-right ml-1"
              @click="chamaExclusao">
              excluir
            </b-button>

            <b-button v-if="event.id < 0 && hasPermission('AGENDA', 'GRAVAR', 'E')" variant="primary" size="md"
              class="btn-fill float-right ml-1" @click="registrarEvento">
              Salvar
            </b-button>

            <b-button v-show="perfilLogado !== 'PROF'" v-if="event.id > 0 && hasPermission('AGENDA', 'GRAVAR', 'E')"
              size="md" variant="primary" class="btn-fill float-right" @click="chamaEdicao">Atualizar</b-button>
            </div>
        
      </template>

    </b-modal>  
  </div>
  
</template>

<script>
import AgendaService from "../service/AgendaService";
import { agenda } from "../model/agenda";
import { VMoney } from 'v-money'
import { BASE_CONSTANTS } from "../service/config";
import axios from "axios";
import appUtils from '@/composables/utils'
import useAuthentication from '@/composables/useAuthentication'
import Loading from '../layout/Loading'
import SelectProfissional from './comuns/SelectProfissional.vue'
import Confirma from './comuns/Confirma'
import FiltroLocal from './comuns/FiltroLocal'

export default {
  components: {
    Loading,
    SelectProfissional,
    Confirma,
    FiltroLocal,    
  },
  data() {
    return {
      show: false,
      event: null,
      iniciou: false,
      idLocalEscala: null,
      idTipoEscala: null,
      replicarAlteracao: false,
      pagamentoAvista: 'N',
      multiplaData: false,
      modoLancamento: 'individual',
      eventoAnt: null,
      acaoConfirma: '',
      mensagemEvento: null,
      confirmarExclusao: false,
      confirmarEdicao: false,
      confirmar: true,
      diaSemana: [],
      tipoApuracao: '',
      idAgenda: null,
      idRecorrencia: null,
      perfilLogado: "",
      existeRecorrencia: false,
      editRecorrencia: false,
      permiteRecorrencia: false,
      tipoRecorrencia: null,
      show: false,
      showFrom: "",
      filaShow: [],
      replicarStatus: false,
      http: null,
      msg: "",
      textoLoad: 'Aguarde',
      duracao: null,
      dtAgenda: null,
      dtAgendas: [],
      dtUltimaAlteracao: null,
      horarioDe: null,
      quantidadeEscala: null,
      idProfissional: null,
      idProfissionalAlocado: null,
      idProfissionalPnc: null,
      nomeProfissionalPnc: null,
      idProfissionalAlocadoPnc: null,
      nomeProfissionalAlocadoPnc: null,
      nomeProfissional: "",
      telefoneProfissional: "",
      nomeProfissionalAgenda: "",
      editNomeProfissionalAgenda: "",
      idProfissionalEfetivo: null,
      nomeProfissionalEfetivo: null,
      idProfissionalFavorecido: null,
      nomeProfissionalFavorecido: "",
      campoSelectProfissional: '',
      idLocal: null,
      idEmprea: null,
      nomeLocal: "",
      idTipo: null,
      dscTipo: "",
      end: null,
      idSituacao: null,
      idMotivoTroca: null,
      dscMotivoTroca: "",
      dscSituacao: "",
      obs: "",
      obsPublica: "",
      valorCompl: 0,
      valorCompra: 0,
      unidade: 0,
      valorTotalCompra: 0,
      historico: "",
      mostrarObs: false,
      mostrarValCompl: false,
      mostrarProfissionalEfetivo: false,
      mostrarFixo: 'N',
      fixo: null,
      modoPlanejamento: 'S',
      duracaoPrevista: null,
      quantidadePrevista: null,
      competencia: null,
      // variaveis auxiliares
      calendarApi: null,
      eventosArmazenados: [],
      itensSituacao: [],
      itensMotivoTroca: [],
      itensProfissionalPlantao: [],
      itensLocal: [],
      itensTipo: [],
      historicos: null,
      idResponsavelAprovacao: null,
      idResponsavelPagamento: null,
      itensUsuariosAprovadores: null,
      itensResponsavelPagamento: null,
      itensPlanejamento: [{ "value": "P", "text": "Previsto" }, { "value": "A", "text": "Não previsto" }],
      fields: [],
      menu: null,
      dates: [],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      decimal: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      vincularProfLocal: null,
      confirmacoes: null,
      selected: null,
    }
  },
  directives: { money: VMoney },
  methods: {  
    showLoad(texto) {
      this.textoLoad = texto ? texto : 'Aguarde...'
      this.show = true
    },  
    closeLoad() {
      this.show = false
    },  
    fechar() {
      this.$bvModal.hide('modal-event-modal')
    },
    async abrir(arg) {
      this.showLoad()
      this.iniciou = false  
      this.limparTela()                
      this.event = arg
      this.idLocalEscala = arg.idLocalEscala
      this.idTipoEscala = arg.idTipoEscala
      this.$bvModal.show('modal-event-modal')      
      await this.iniciarEvento()      
      this.iniciou = true
      this.closeLoad()
    },
    async iniciarEvento() {
      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
      
      this.confirmacoes = null
      await this.preencheMotivoTroca()
      if (this.event.id > 0) {
        let idConsulta =
          this.event.id > 0
            ? this.event.id
            : this.event.idRecorrencia;

        let dadosConsulta = {
          id: idConsulta,
          idEmpresa: dadosUsuario.perfilCoordenador === 'S' ? dadosUsuario.grupo.empresa.id : null,
          coordenador: dadosUsuario.perfilCoordenador,
        };

        const ret = await AgendaService.listaEventosFilter(dadosConsulta)
          .then((resposta) => {
            
            let evento = resposta.data.obj[0];

            this.eventoAnt = evento;
            if (evento !== undefined && evento !== null) {

              this.idAgenda = evento.id;

              this.horarioDe = evento.horarioDe;
              this.duracao = this.convertMinutosEmHora(evento.duracao);

              this.idProfissional = evento.idProfissional
              this.nomeProfissional = evento.nomeProfissional
              this.telefoneProfissional = evento.telefoneProfissional
              this.idProfissionalEfetivo = evento.idProfissionalEfetivo
              this.nomeProfissionalEfetivo = evento.nomeProfissionalEfetivo
              this.idLocal = evento.idLocal
              this.idEmpresa = evento.idEmpresa

              this.nomeLocal = evento.nomeLocal
              this.nomeProfissionalAgenda = evento.nomeProfissionalAgenda

              this.idTipo = evento.idTipo
              this.dscTipo = evento.dscTipo
              this.idSituacao = evento.idSituacao
              this.dscSituacao = evento.dscSituacao

              this.dtAgenda = evento.dtAgendaStr
              this.competencia = evento.competenciaStr

              this.dtUltimaAlteracao = new Date(evento.dtUltimaAlteracaoStr)

              this.end = evento.end
              this.idRecorrencia = evento.idRecorrencia
              this.obs = evento.obs
              this.obsPublica = evento.obsPublica
              this.valorCompl = formatarValorNumerico(evento.valorCompl)
              this.valorCompra = formatarValorNumerico(evento.valorCompra)
              this.valorTotalCompra = formatarValorNumerico(evento.valorTotalCompra)
              this.pagamentoAvista = evento.avista
              this.quantidadeEscala = evento.quantidadeEscala ? formatarValorNumerico(evento.quantidadeEscala) : 0
              this.existeRecorrencia = this.idRecorrencia !== null && this.idRecorrencia > 0
              this.mostrarObs = this.obs !== ""
              this.mostrarValCompl = evento.valorCompl > 0
              this.fixo = evento.fixo
              this.idMotivoTroca = evento.idMotivoTroca
              this.dscMotivoTroca = evento.dscMotivoTroca

              this.idProfissionalPnc = evento.idProfissionalPnc
              this.nomeProfissionalPnc = evento.nomeProfissionalPnc

              this.idProfissionalAlocadoPnc = evento.idProfissionalEfetivoPnc
              this.nomeProfissionalAlocadoPnc = evento.nomeProfissionalEfetivoPnc

              this.idProfissionalFavorecido = evento.idProfissionalFavorecido
              this.nomeProfissionalFavorecido = evento.nomeProfissionalFavorecido

              this.tipoApuracao = evento.tipoApuracao
              this.unidade = evento.unidade

              this.duracaoPrevista = this.convertMinutosEmHora(evento.duracaoPrevista);
              this.quantidadePrevista = evento.quantidadePrevista
              this.modoPlanejamento = evento.modoPlanejamento

              this.idResponsavelAprovacao = evento.idUsuRespAprovacao
              this.idResponsavelPagamento = evento.idClassifRespPagamento

              return true

            } else {
              //alert("Agenda não encontrada.");
              this.event.remove();
              this.$emit("close");
            }
          }).catch((err) => {    
            const { isUnauthorized } = useAuthentication()
            const isLogin = isUnauthorized(err)
            if (isLogin) {
              this.$emit('close')
              this.$router.push("/login")
            }
          });

        if (ret) await this.carregaDados()

      } else {
        this.permiteRecorrencia = true;
        this.idLocal = this.idLocalEscala
        this.idTipo = this.idTipoEscala        
        this.dtAgenda = this.event.dtAgenda !== undefined && this.event.dtAgenda !== null
          ? formatDate(this.event.dtAgenda) : new Date()
        await this.carregaDados()
      }
    },
    limparTela() {      
      this.idLocalEscala = null
      this.idTipoEscala = null
      this.replicarAlteracao = false
      this.pagamentoAvista = 'N'
      this.multiplaData = false
      this.modoLancamento = 'individual'
      this.eventoAnt = null
      this.mensagemEvento = null
      this.confirmarExclusao = false
      this.confirmarEdicao = false
      this.confirmar = true
      this.diaSemana = []
      this.tipoApuracao = ''
      this.idAgenda = null
      this.idRecorrencia = null      
      this.existeRecorrencia = false
      this.editRecorrencia = false
      this.permiteRecorrencia = false
      this.tipoRecorrencia = null
      this.replicarStatus = false            
      this.duracao = null
      this.dtAgenda = null
      this.dtAgendas = []
      this.competencia = null
      this.dtUltimaAlteracao = null
      this.horarioDe = null
      this.quantidadeEscala = null
      this.idProfissional = null
      this.idProfissionalAlocado = null
      this.idProfissionalPnc = null
      this.nomeProfissionalPnc = null
      this.idProfissionalAlocadoPnc = null
      this.nomeProfissionalAlocadoPnc = null
      this.nomeProfissional = ''
      this.telefoneProfissional = ''
      this.nomeProfissionalAgenda = ''
      this.editNomeProfissionalAgenda = ''
      this.idProfissionalEfetivo = null
      this.nomeProfissionalEfetivo = null
      this.campoSelectProfissional = ''
      this.idLocal = null
      this.idEmprea = null
      this.nomeLocal = ''
      this.idTipo = null
      this.dscTipo = ''
      this.end = null
      this.idSituacao = null
      this.idMotivoTroca = null
      this.dscMotivoTroca = ''
      this.dscSituacao = ''
      this.obs = ''
      this.obsPublica = ''
      this.valorCompl = 0
      this.valorCompra = 0
      this.unidade = 0
      this.valorTotalCompra = 0
      this.historico = ''
      this.mostrarObs = false
      this.mostrarValCompl = false
      this.mostrarProfissionalEfetivo = false
      this.mostrarFixo = 'N'
      this.fixo = null    
      this.modoPlanejamento = 'P'    
      this.idProfissionalFavorecido = null
      this.eventosArmazenados = []
      this.itensSituacao = []
      this.itensMotivoTroca = []
      this.itensProfissionalPlantao = []
      this.itensLocal = []
      this.itensTipo = []
      this.historicos = null
      this.idResponsavelAprovacao = null
      this.idResponsavelPagamento = null
      this.itensUsuariosAprovadores = null
      this.itensResponsavelPagamento = null
      this.dates = []
      this.vincularProfLocal = null
      this.confirmacoes = null
      this.selected = null 
    },
    filterBy(option, label, search) {
      const { filterBy } = appUtils(this)
      return filterBy(option, label, search)
    },
    alteraModoLancamento() {
      this.dtAgendas = []
      if (this.modoLancamento === 'datas') {
        this.adicionarData(this.dtAgenda)
      }
    },
    adicionarData(d) {
      if (this.modoLancamento === 'datas') {
        this.dtAgendas.push(
          d
        )
      }
    },
    alterarProfissionalPlantao(id) {
      this.vincularProfLocal = null
      if (!this.isPnc) {
        this.idProfissionalPnc = null
        this.nomeProfissionalPnc = null
      }
    },
    alterarProfissionalAlocado(id) {

      let alterar = !this.idMotivoTroca || this.profissionalInformado === null
      if (alterar) {
        if (this.isListaProfissionalAlocado) {
          if (this.isListaProfissional) {
            this.idProfissional = []
            if (id.length > 0) {
              this.idProfissional.push(id[0]);
            }
          } else {
            this.idProfissional = id[0];
          }
        } else {
          if (this.isListaProfissional) {
            this.idProfissional = []
            if (id !== null) {
              this.idProfissional.push(id);
            }
          } else {
            this.idProfissional = id;
          }
        }
        if (!this.idProfissionalPnc || !this.isProfissionalPlantaoLiberado) {
          this.idProfissionalPnc = this.idProfissionalAlocadoPnc
          this.nomeProfissionalPnc = this.nomeProfissionalAlocadoPnc
        }
      }

    },
    alterarProfissionalFavorecido(id) {
    },
    alterarLocal(item) {      
      this.idTipo = null
      this.preencheProfissionais(item)
      this.preencheTipo(item)
      this.preencheSituacao(item)
    },
    preencheProfissionais(item) {

      const { ativarShow, desativarShow } = appUtils(this)

      var idProfissionalAnt = this.idProfissional;
      var idProfissionalEfetivoAnt = this.idProfissionalEfetivo;
      this.idProfissional = null;
      if (item !== null) {
        let idLocal = item;

        //ativarShow("preencheProfissionais")

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          "idLocal": idLocal !== null && idLocal !== 0 ? idLocal : 0,
          "idEmpresa": dadosUsuario.grupo.empresa.id
        }

        AgendaService.listaProfissionaisFilter(this.http, dadosConsulta)
          .then((resposta) => {
            this.itensProfissionalPlantao = resposta.data.obj;
            if (idProfissionalAnt !== null) {
              var profissional = this.itensProfissionalPlantao.find(p => p.id === idProfissionalAnt);
              if (profissional !== undefined) {
                this.idProfissional = profissional.id
              } else {
                this.idProfissional = null
              }
            }
            if (idProfissionalEfetivoAnt !== null) {
              var profissional = this.itensProfissionalPlantao.find(p => p.id === idProfissionalEfetivoAnt);
              if (profissional !== undefined) {
                this.idProfissionalEfetivo = profissional.id
              } else {
                this.idProfissionalEfetivo = null
              }
            }
            //desativarShow("preencheProfissionais")
          })
          .catch((error) => {
            //desativarShow("preencheProfissionais")
          });

      } else {
        this.itensProfissionalPlantao = [];
      }
    },
    async preencheTipo(item) {
      if (item !== null) {
        let idLocal = item;

        const { ativarShow, desativarShow } = appUtils(this)

        //ativarShow("preencheTipo")

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idLocal": idLocal,
          "ativo": 'S',
          "data": this.dtAgenda,
          "idTabela": dadosUsuario.grupo.idTabela
        }

        await AgendaService.listaTiposFilter(dadosConsulta)
          .then((resposta) => {
            this.itensTipo = resposta.data.obj;
            //desativarShow("preencheTipo")
            this.buscarTipoForaDaLista()
            if (this.idTipo && this.idTipo > 0) {
              this.alterarTipo(this.idTipo, this.isLancamentoNovo);
            } else if (this.itensTipo.length === 1) {
              this.idTipo = this.itensTipo[0].id
              this.alterarTipo(this.idTipo, this.isLancamentoNovo);
            }
          })
          .catch((error) => {
            //desativarShow("preencheTipo")
          });

      } else {
        this.itensTipo = [];
      }

    },
    async preencheSituacao(local) {

      if (local !== null && local > 0) {

        const { ativarShow, desativarShow } = appUtils(this)

        //ativarShow("preencheSituacao")

        let idLocal = local;

        let idItemSituacaoAtual = this.idSituacao;

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          "idUsuario": dadosUsuario.idPessoa,
          "idGrupoUsuario": dadosUsuario.grupo.id,
          "idMandatorio": idItemSituacaoAtual !== null ? idItemSituacaoAtual : 0,
          "idLocal": idLocal,
          "acima": true
        }

        await AgendaService.recuperaSituacoes(dadosConsulta)
          .then((resposta) => {

            this.itensSituacao = resposta.data.obj;

            if (idItemSituacaoAtual !== undefined && idItemSituacaoAtual !== null) {
              this.idSituacao = idItemSituacaoAtual
              this.alterarSituacao(this.idSituacao, false)
            } else {
              let situacaoPadrao = this.itensSituacao.filter((i) => { return i.padrao === 'S' })
              if (situacaoPadrao.length > 0) {
                this.idSituacao = situacaoPadrao[0].id
                this.alterarSituacao(this.idSituacao, false)
              }
            }

            //desativarShow("preencheSituacao")
          })
          .catch((error) => {
            //desativarShow("preencheSituacao")
          });

      } else {
        this.itensSituacao = [];
      }
    },
    async preencheMotivoTroca() {
      if (this.itensMotivoTroca.length === 0) {

        const { ativarShow, desativarShow } = appUtils(this)

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          idUsuario: dadosUsuario.idPessoa,
          idGrupoUsuario: dadosUsuario.grupo.id,
          idEmpresa: dadosUsuario.grupo.empresa.id,
          idTipo: 4
        }

        await AgendaService.listaSituacoesGeral(dadosConsulta)
          .then((resposta) => {
            this.itensMotivoTroca = resposta.data.obj;
          })
          .catch((err) => {
            //desativarShow("preencheMotivoTroca")
          });
      }
    },
    recarregarListaTipo(){
      this.preencheTipo(this.idLocal)
    },
    alterarTipo(item, mudou) {
      let obj = this.itensTipo.find((o) => o.id === item)
      if (mudou) {
        this.preencheDuracao(obj)
        this.diaSemana = obj !== undefined ? obj.agendaDiaSemana : []
        this.tipoApuracao = obj !== undefined ? obj.tipoApuracao : ''
        this.valorCompra = obj !== undefined ? formatarValorNumerico(obj.valorCompra) : 0
        this.unidade = obj !== undefined ? obj.unidade : ''
      }
    },
    preencheDuracao(item) {
      this.horarioDe = item !== undefined ? item.horaInicio : "";
      this.duracao = item !== undefined ? this.convertMinutosEmHora(item.duracao) : "";
    },
    alterarSituacao(item, mudou) {

      let obj = this.itensSituacao.find((o) => o.id === item);
      this.mostrarFixo = obj != null ? obj.divulga : 'N'
      if (this.mostrarFixo === 'N') {
        this.fixo = null
      }

    },
    async carregaDados() {

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let dadosConsulta = []

      if (this.idLocal !== undefined && this.idLocal !== null && this.idLocal !== 0) {        

        if (this.$refs.filtroLocal) {
          this.$refs.filtroLocal.carregaLocais(this.idLocal)
        }
        await this.preencheTipo(this.idLocal)        
        await this.preencheSituacao(this.idLocal)

        dadosConsulta = {
          idLocal: this.idLocal,
          idEmpresa: dadosUsuario.grupo.empresa.id
        }

        await AgendaService.listaProfissionaisFilter(this.http, dadosConsulta)
          .then((resposta) => {
            this.itensProfissionalPlantao = resposta.data.obj;            
            this.buscarProfissionalForaDaLista()            
          }).catch((error) => {
            
          })
      }

      if (this.idMotivoTroca !== undefined && this.idMotivoTroca !== null && this.idMotivoTroca !== 0) {
        await this.preencheMotivoTroca()
      }
      
    },
    salvaEvento(evento) {
      if (!this.validaCampos(evento)) {
        return false;
      }
      return true;
    },
    convertHoraEmMinutos(data) {
      if (data !== null && data !== undefined) {
        let f0 = data.split(":");
        let minutos = Number(f0[0]) * 60 + Number(f0[1]);
        return minutos;
      }
    },
    convertMinutosEmHora(minutos) {
      if (minutos > 0) {
        const horas = Math.floor(minutos / 60);
        const min = minutos % 60;
        const textoHoras = `00${horas}`.slice(-2);
        const textoMinutos = `00${min}`.slice(-2);
        return `${textoHoras}:${textoMinutos}`;
      } else {
        return '';
      }
    },
    replicarEvento() {
      this.editRecorrencia = true;
      this.registrarEvento();
    },
    registrarEvento(confirmando) {

      const { toast } = appUtils(this)

      this.showLoad('Salvando...')

      if (!confirmando) this.confirmacoes = null

      let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

      let agendaDiaSemana = [];
      if (this.modoLancamento === 'replicar') {
        this.diaSemana.forEach((diaSemana) => {
          if (diaSemana.selecionado === true) {
            agendaDiaSemana.push(diaSemana);
          }
        });
      }

      agenda.id = this.event.id > 0 ? this.event.id : null
      agenda.duracao = this.convertHoraEmMinutos(this.duracao)
      agenda.horarioDe = this.horarioDe
      agenda.dtAgenda = this.dtAgenda
      agenda.dtAgendaStr = this.dtAgenda
      agenda.competenciaStr = this.competencia

      if (this.modoLancamento === 'datas') {
        agenda.dtAgendasStr = this.dtAgendas
      } else {
        agenda.dtAgendasStr = null
      }
      if (this.dtUltimaAlteracao != null) {
        agenda.dtUltimaAlteracaoStr = dateToStr(this.dtUltimaAlteracao, 'yyyy-MM-dd HH:mm:ss')
      }

      if (this.isPnc) {
        agenda.idProfissionalPnc = this.idProfissionalPnc
      } else {
        agenda.idProfissionalPnc = null
      }

      if (this.isPncAlocado) {
        agenda.idProfissionalEfetivoPnc = this.idProfissionalAlocadoPnc
      } else {
        agenda.idProfissionalEfetivoPnc = null
      }

      agenda.allDay = true
      agenda.idSituacao = this.idSituacao

      if (agenda.id == null) {
        agenda.idsProfissional = this.idProfissional
        agenda.idProfissional = null
        agenda.idsProfissionalEfetivo = this.idProfissionalEfetivo
        agenda.idProfissionalEfetivo = null
      } else {
        agenda.idsProfissional = null
        agenda.idProfissional = this.idProfissional
        agenda.idsProfissionalEfetivo = null
        agenda.idProfissionalEfetivo = this.idProfissionalEfetivo
      }

      agenda.idProfissionalFavorecido = this.idProfissionalFavorecido

      agenda.idTipo = this.idTipo
      agenda.idLocal = this.idLocal
      agenda.agendaDiaSemana = agendaDiaSemana
      agenda.end = this.end
      agenda.obs = this.obs
      agenda.obsPublica = this.obsPublica
      agenda.valorCompl = parseFloat(this.valorCompl.toString().replace("R$ ", "").replace(".", "").replace(",", "."))
      agenda.idRecorrencia = this.idRecorrencia
      agenda.editRecorrencia = this.editRecorrencia
      agenda.idUsuario = dadosUsuario.idPessoa
      agenda.replicar = this.replicarStatus
      agenda.historico = this.historico
      agenda.nomeProfissionalAgenda = this.nomeProfissionalAgenda
      agenda.avista = this.pagamentoAvista
      agenda.fixo = this.fixo
      //agenda.troca = this.troca ? 'S' : 'N'
      agenda.idMotivoTroca = this.idMotivoTroca
      agenda.vincularProfLocal = this.vincularProfLocal

      agenda.quantidadeEscala = this.quantidadeEscala != null ? parseFloat(this.quantidadeEscala.toString().replace(".", "").replace(",", ".")) : null

      agenda.confDuracao = this.confirmacoes && this.confirmacoes.find((f) => f === 'DURACAO') !== undefined ? 'S' : null
      agenda.confEfetivo = this.confirmacoes && this.confirmacoes.find((f) => f === 'EFETIVO') !== undefined ? 'S' : null

      agenda.idTabela = dadosUsuario.grupo.idTabela
      agenda.idUsuRespAprovacao = this.idResponsavelAprovacao;
      agenda.idClassifRespPagamento = this.idResponsavelPagamento;

      agenda.duracaoPrevista = this.convertHoraEmMinutos(this.duracaoPrevista)
      agenda.quantidadePrevista = this.quantidadePrevista != null ? parseFloat(this.quantidadePrevista.toString().replace(".", "").replace(",", ".")) : null
      agenda.modoPlanejamento = this.modoPlanejamento

      //let context = this;

      AgendaService.salvarEvento(this.http, agenda).then((resposta) => {        
        let retorno = resposta.data;        
        if (retorno.status === "SUCCESS") {

          this.confirmacoes = null
          this.event.api.unselect();

          //let duracao = this.convertHoraEmMinutos(context.duracao);
          //let duracaoHoraMinuto = context.duracao;

          let listRetorno = [];

          listRetorno = retorno.obj;

          listRetorno.forEach((item) => {

            this.$emit('atualizar', item)

            /*var event = context.event.api.getEventById(item.id);

            if (event != null) {
              event.remove()
            }

            context.event.api.addEvent({
              id: item.id,
              horarioDe: context.horarioDe,
              dtAgenda: item.dtAgenda,
              dtUltimaAlteracao: new Date(item.dtUltimaAlteracaoStr),
              duracaoHoraMinuto: duracaoHoraMinuto,
              duracao: duracao,
              horarioAte: item.horarioAte,
              allDay: true,
              start: item.dtAgenda,
              end: item.dtAgenda,
              title: item.nomeProfissional,
              idLocal: item.idLocal,
              nomeLocal: item.nomeLocal,
              idTipo: item.idTipo,
              dscTipo: item.dscTipo,
              idProfissional: item.idProfissional,
              nomeProfissional: item.nomeProfissional,
              nomeProfissionalComCrm: item.nomeProfissionalComCrm,
              idSituacao: item.idSituacao,
              idRecorrencia: item.idRecorrencia,
              cor: item.cor,
              corTipo: item.corTipo,
              corLocal: item.corLocal,
              corFundo: item.corFundo,
              idProfissionalEfetivo: item.idProfissionalEfetivo,
              nomeProfissionalEfetivo: item.nomeProfissionalEfetivo,
              idProfissionalPnc: item.idProfissionalPnc,
              nomeProfissionalPnc: item.nomeProfissionalPnc,
              idMotivoTroca: item.idMotivoTroca,
              avista: item.avista,
              valorCompl: item.valorCompl,
              fixo: item.fixo,
              ordem: 0
            });
            */

          });

          if (localStorage.getItem("eventosArmazenados") !== null) {
            localStorage.setItem("eventosArmazenados", null)
            //desativarShow("registrarEvento")
            //this.closeLoad()
            this.closeLoad()
            //this.$emit("close")
            this.fechar()
          } else {
            localStorage.setItem("eventosArmazenados", null)
            //this.closeLoad()
            this.closeLoad()
            //desativarShow("registrarEvento")
            //this.$emit("close");
            this.fechar()
          }

        } else if (retorno.status === "CONFIRM") {
          this.closeLoad()
          this.confirmacoes = retorno.obj;
          //this.mensagemEvento = retorno.mensagens[0].descricao          
          //this.$refs.conf.show()
          this.abrirConfirmar('confirmacoes-salvar', retorno.mensagens[0].descricao)
        } else {
          this.closeLoad()
          if (retorno.mensagens.length > 0) {
            toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
          }
          this.confirmacoes = null
          this.confirmarEdicao = false;
          this.closeLoad()
        }

      }).catch((err) => {
        this.confirmarEdicao = false;
        //desativarShow("registrarEvento")
        //this.closeLoad()
        this.closeLoad()
        //this.$emit("close");
        this.fechar()
      });
    },
    confirmarDados() {
      this.registrarEvento(true)
    },
    validarReplicacao() {
      if (
        this.eventoAnt.idTipo !== this.idTipo ||
        this.eventoAnt.idLocal !== this.idLocal ||
        (this.eventoAnt.idProfissional !== null &&
          this.eventoAnt.idProfissional !== this.idPprofissional) ||
        this.eventoAnt.horarioDe !== this.horarioDe
      ) {
        return true;
      } else {
        return false;
      }
    },
    abrirConfirmar(acao, message) {
      this.acaoConfirma = acao
      this.mensagemEvento = message
      this.$refs.conf.show()
    },
    continuarConfirmar(r) {
      if (this.acaoConfirma === 'confirmacoes-salvar') {
        if (r === 'S') this.confirmarDados()
        else this.confirmacoes = null
      } else if (this.acaoConfirma === 'excluir') {
        if (r === 'S') this.deleteEvent()
      } else if (this.acaoConfirma === 'excluir-replicar') {
        if (r === 'S') this.deleteEventReplicar()
        else this.deleteEvent()
      }      
    },
    chamaEdicao() {
      this.replicarAlteracao = false// this.validarReplicacao()
      if (this.existeRecorrencia && this.replicarAlteracao) {
        this.mensagemEvento = "Deseja replicar alterações?"
        this.confirmarExclusao = false
        this.confirmarEdicao = true
        this.confirmar = false
      } else {        
        this.registrarEvento()
      }
    },
    chamaExclusao() {
      this.replicarAlteracao = false
      if (this.existeRecorrencia) {
        //this.mensagemEvento = "Deseja replicar exclusão?"
        this.confirmarExclusao = true
        this.confirmarEdicao = false
        this.confirmar = false
        this.abrirConfirmar('excluir-replicar', 'Deseja replicar exclusão?')
      } else {
        this.abrirConfirmar('excluir', 'Confirma exclusão?')        
      }
    },
    cancelar() {
      this.confirmarExclusao = false
      this.confirmarEdicao = false
      this.confirmar = true
    },
    deleteEventReplicar() {
      this.replicarAlteracao = true
      this.deleteEvent();
    },
    deleteEvent() {

      const { toast } = appUtils(this)

      this.showLoad('Excluindo...')
      localStorage.setItem("dataEventoSelecionado", this.event.dtAgenda);
      this.event.api.unselect();

      agenda.id = this.event.id;

      AgendaService.excluiEvento(this.http, agenda.id, this.replicarAlteracao)
        .then((resposta) => {
          let retorno = resposta.data

          if (retorno.status === "DELETED") {
            let listRetorno = [];
            listRetorno = retorno.agendas

            listRetorno.forEach((item) => {
              this.$emit('excluir', item)
              /*var event = this.event.api.getEventById(item.id)
              if (event != null) {
                event.remove();
              }*/
            });

            this.fechar()

          } else {
            if (retorno.mensagens.length > 0) {
              toast(retorno.mensagens[0].descricao, 'Falha', 'danger')
            }
          }

          this.closeLoad()
        })
        .catch((error) => {
          this.closeLoad()
          this.fechar()
        });
    },
    validarNumber(e) {
      let stringValue = e.target.value.toString();
      let regex = /^\d*(\,\d{1,2})?$/;
      if (!stringValue.match(regex) && this.price !== "") {
        this.price = this.previousPrice;
      }
      this.previousPrice = this.price;
    },
    onlyForCurrency($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      let val = $event.target.value.toString();

      // only allow number and one dot
      if (
        (keyCode < 48 || keyCode > 57) &&
        (keyCode !== 44 || val.indexOf(",") !== -1)
      ) {
        // 46 is dot - 44 is comma
        $event.preventDefault();
      }

      // restrict to 2 decimal places
      if (
        val !== null &&
        val !== undefined &&
        val.indexOf(",") > -1 &&
        val.split(",")[1].length > 1
      ) {
        $event.preventDefault();
      }
    },
    buscarHistorico() {

      if (this.historicos === null) {

        const { toast, ativarShow, desativarShow } = appUtils(this)

        //ativarShow("buscarHistorico")
        this.showLoad()

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));
        let dadosConsulta = dadosConsulta = '{ "idUsuario": ' + dadosUsuario.idPessoa +
          ', "idAgenda": ' + this.event.id +
          '}';

        AgendaService.listaHistoricoAgenda(this.http, dadosConsulta)
          .then((resposta) => {
            this.historicos = resposta.data.obj
            //desativarShow("buscarHistorico")
            this.closeLoad()
          })
          .catch((error) => {
            //desativarShow("buscarHistorico")
            this.closeLoad()
          });

      }
    },
    strDateFormat(dataStr, format) {
      let data = new Date(dataStr + " (UTC-3)");
      return dateToStr(data, format);
    },
    /*hasPermission(menu, permissao) {        
        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));        
        let perm = dadosUsuario.grupo.permissoes.filter(function(elem){      
          if(elem.menu == menu && elem.permissao == permissao) return elem;
          });
        return perm !== null && perm.length>0 ? perm[0].nivel : 'E';  
    },*/
    editDadosProfissional() {

      if (this.idProfissional !== null) {
        let idProcurar = this.idProfissional
        if (this.event.id < 0) {
          idProcurar = this.idProfissional != null && this.idProfissional.length > 0 ? this.idProfissional[0] : 0
        }
        var profissional = this.itensProfissionalPlantao.find(p => p.id === idProcurar);
        if (profissional !== undefined) {
          return profissional.pnc === 'S'
          //return ['pnc', 'profissional efetivo'].includes(profissional.nome.toLowerCase());
          //return profissional.nome === 'PNC';
        }
      }

      return false;
    },
    abrirEdicaoDadosProfissional() {

      let dados = {
        nome: this.nomeProfissionalAgenda
      }

      this.$refs.editarDadosProfissional.abrir(dados)

      /*this.editNomeProfissionalAgenda = this.nomeProfissionalAgenda
      this.$bvModal.show('modal-dados-profissional')*/
    },
    confirmarDadosProfissional(v) {
      this.nomeProfissionalAgenda = v.nome
      /*this.$bvModal.hide('modal-dados-profissional')*/
    },
    selecionarProfissional(p) {

      if (this.campoSelectProfissional === 'alocado') {
        this.vincularProfLocal = null
        let prof = this.profissionalAlocadoInformado

        if (prof && prof.pnc === 'S') {
          this.idProfissionalAlocadoPnc = p.id
          this.nomeProfissionalAlocadoPnc = p.nome
        } else {
          var profLista = this.itensProfissionalPlantao.find(pl => pl.id === p.id)
          if (profLista === undefined) {
            this.itensProfissionalPlantao.push({
              id: p.id,
              nome: p.nome,
              crm: p.crm,
              ufCrm: p.ufCrm,
              cpf: p.cpf
            })
            this.vincularProfLocal = 'S'
          }

          if (this.event.id < 0) {
            var profSelect = this.idProfissionalEfetivo != null ? this.idProfissionalEfetivo.find((id) => id === p.id) : undefined;
            if (profSelect === undefined) {
              if (this.idProfissionalEfetivo === null) this.idProfissionalEfetivo = []
              this.idProfissionalEfetivo.push(p.id)
            }
          } else {
            this.idProfissionalEfetivo = p.id
          }
        }

        this.alterarProfissionalAlocado(this.idProfissionalEfetivo)

      } else if (this.campoSelectProfissional === 'plantao') {
        this.vincularProfLocal = null
        let prof = this.profissionalInformado

        if (prof && prof.pnc === 'S') {
          this.idProfissionalPnc = p.id
          this.nomeProfissionalPnc = p.nome
        } else {
          var profLista = this.itensProfissionalPlantao.find(pl => pl.id === p.id)
          if (profLista === undefined) {
            this.itensProfissionalPlantao.push({
              id: p.id,
              nome: p.nome,
              crm: p.crm,
              ufCrm: p.ufCrm,
              cpf: p.cpf
            })
            this.vincularProfLocal = 'S'
          }

          if (this.event.id < 0) {
            var profSelect = this.idProfissional != null ? this.idProfissional.find((id) => id === p.id) : undefined;
            if (profSelect === undefined) {
              if (this.idProfissional === null) this.idProfissional = []
              this.idProfissional.push(p.id)
            }
          } else {
            this.idProfissional = p.id
          }
        }

      }else{
        this.vincularProfLocal = null
          var profLista = this.itensProfissionalPlantao.find(pl => pl.id === p.id)
          if (profLista === undefined) {
            this.itensProfissionalPlantao.push({
              id: p.id,
              nome: p.nome,
              crm: p.crm,
              ufCrm: p.ufCrm,
              cpf: p.cpf
            })
          }          
          this.idProfissionalFavorecido = p.id        
      }
    },
    buscarProfissionalForaDaLista() {
      if (this.idEmpresa && this.idProfissional) {

        var profissional = this.itensProfissionalPlantao.find(p => p.id === this.idProfissional);

        if (profissional === undefined) {

          let dadosConsulta = {
            idEmpresa: this.idEmpresa,
            idProfissional: this.idProfissional
          }

          const { ativarShow, desativarShow } = appUtils(this)

          //ativarShow("buscarProfissionalForaDaLista")

          AgendaService.listaProfissionaisFilter(this.http, dadosConsulta)
            .then((resposta) => {
              if (resposta.data.obj.length > 0) {
                this.itensProfissionalPlantao.push(resposta.data.obj[0]);
              }
              //desativarShow("buscarProfissionalForaDaLista")
            })
            .catch((error) => {
              //desativarShow("buscarProfissionalForaDaLista")
            });
        }
      }

    },
    buscarTipoForaDaLista() {
      if (this.idTipo) {

        var tipo = this.itensTipo.find(t => t.id === this.idTipo);

        if (tipo === undefined) {

          let dadosConsulta = {
            id: this.idTipo,
            simples: 'S'
          }

          const { ativarShow, desativarShow } = appUtils(this)

          //ativarShow("buscarTipoForaDaLista")

          AgendaService.listaTiposFilter(dadosConsulta)
            .then((resposta) => {
              if (resposta.data.obj.length > 0) {
                this.itensTipo.push(resposta.data.obj[0]);
              }
              //desativarShow("buscarTipoForaDaLista")
            })
            .catch((error) => {
              //desativarShow("buscarTipoForaDaLista")
            });
        }
      }
    },
    buscarUsuariosAprovadores() {
      if (this.itensUsuariosAprovadores == null) {

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          idEmpresa: dadosUsuario.grupo.empresa.id,
        }

        AgendaService.listabuscarUsuariosAprovadores(dadosConsulta)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
              this.itensUsuariosAprovadores = retorno.obj
              if (this.itensUsuariosAprovadores.length==1) {
                this.idResponsavelAprovacao = this.itensUsuariosAprovadores[0].id
              }
            }
          })
          .catch((error) => {

          });

      }
      return this.itensUsuariosAprovadores
    },
    buscarResponsavelPagamento() {
      if (this.itensResponsavelPagamento == null) {

        let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

        let dadosConsulta = {
          idEmpresa: dadosUsuario.grupo.empresa.id,
          identificadoresTipoGeral: ['RPG']
        }

        AgendaService.listaClassificacao(dadosConsulta)
          .then((resposta) => {
            let retorno = resposta.data;
            if (retorno.status === "SUCCESS") {
              this.itensResponsavelPagamento = retorno.obj
              if (this.itensResponsavelPagamento.length==1) {
                this.idResponsavelPagamento = this.itensResponsavelPagamento[0].id
              }
            }
          })
          .catch((error) => {

          });

      }
      return this.itensResponsavelPagamento
    },
    calcularValorTotalCompra() {

      /*console.log('valor: ' + this.valorCompra)
      console.log('unidade: ' + this.unidade)
      console.log('duracao: ' + this.duracao)
      console.log('valor compl.: ' + this.valorCompl)*/

      let val = 0;
      let qtdPlantao = 1
      let valor = this.valorCompra ? parseFloat(this.valorCompra.toString().replace("R$ ", "").replace(".", "").replace(",", ".")) : 0
      let quantidade = this.quantidadeEscala ? parseFloat(this.quantidadeEscala.toString().replace("R$ ", "").replace(".", "").replace(",", ".")) : 0
      let unidade = this.unidade ? this.unidade : 0
      let duracao = this.duracao ? this.convertHoraEmMinutos(this.duracao) : 0
      let valorCompl = this.valorCompl ? formatarStrParaNumerico(this.valorCompl) : 0

      /*console.log('------------------')
      console.log('quantidade: ' + quantidade)
      console.log('unidade: ' + unidade)
      console.log('duracao: ' + duracao)*/

      if (quantidade > 0) {
        qtdPlantao = quantidade
      } else if (unidade > 0 && duracao > 0) {
        qtdPlantao = duracao / unidade
      }

      /*console.log('qtdplantao: ' + qtdPlantao)
      console.log('valor: ' + valor)      
      console.log('valor compl.: ' + valorCompl)*/

      val = (qtdPlantao * valor) + valorCompl
      //console.log('valor total.: ' + val)
      return val

    },
    abrirSelecionaProfissional(origem) {
      this.campoSelectProfissional = origem
      let idsEliminar = [];
      this.$refs.selectProfissional.abrir(idsEliminar)
    },
    hasPermission(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermission'](menu, permissao, nivel)
    },
    hasPermissionWith(menu, permissao, nivel) {
      return this.$store.getters['auth/hasPermissionWith'](menu, permissao, nivel)
    },
  },
  props: {
    text: String,
    //event: Object,
    //idLocalEscala: Number,
    //idTipoEscala: Number,
  },
  mounted() {

    const token = String(localStorage.getItem("authorization")).replaceAll('"',"");
    if (
      token === null ||
      token === undefined ||
      token === "" ||
      token === "null"
    ){
      return 
    }
    
    let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

    this.perfilLogado = dadosUsuario.grupo.id === 1 ? "COORD" : dadosUsuario.grupo.id === 2 ? "PROF" : "ADM";

    this.http = axios.create({
      baseURL: BASE_CONSTANTS.urlBase,
      headers: {
        Authorization:
          "bearer " +
          String(localStorage.getItem("authorization")).replaceAll('"', ""),
        "Content-Type": "application/json",
        Accept: "*/*",
      },

    });

    this.fields = [
      { label: 'Descrição', key: 'descricao', thStyle: { width: '60%' } },
      { label: 'Data', key: 'data', type: 'date', format: 'dd/MM/yyyy HH:mm:ss', thStyle: { width: '20%' } },
      { label: 'Responsável', key: 'dscUsuario', thStyle: { width: '20%' } },
    ]
  },
  computed: {
    maisDeUmProfissional() {
      return Array.isArray(this.idProfissional) && this.idProfissional.length > 1
    },
    maisDeUmProfissionalAlocado() {
      return Array.isArray(this.idProfissionalEfetivo) && this.idProfissionalEfetivo.length > 1
    },
    isListaProfissional() {
      return Array.isArray(this.idProfissional) || !this.event || this.event.id < 0;
    },
    isListaProfissionalAlocado() {
      return Array.isArray(this.idProfissionalEfetivo) || !this.event || this.event.id < 0;
    },
    isProfissionalInformado() {
      if (this.idProfissional !== null) {
        return this.idProfissional > 0
      }
      return false;
    },
    profissionalAlocadoInformado() {
      let prof = null
      if (this.idProfissionalEfetivo !== null) {
        let id = this.idProfissionalEfetivo
        if (this.isListaProfissionalAlocado) {
          if (this.idProfissionalEfetivo.length > 0) {
            id = this.idProfissionalEfetivo[0]
          }
        }
        prof = this.itensProfissionalPlantao.find(p => p.id === id);
      }
      return prof;
    },
    profissionalInformado() {
      let prof = null
      if (this.idProfissional !== null) {
        let id = this.idProfissional
        if (this.isListaProfissional) {
          if (this.idProfissional.length > 0) {
            id = this.idProfissional[0]
          }
        }
        prof = this.itensProfissionalPlantao.find(p => p.id === id);
      }
      return prof;
    },
    isPncAlocado() {
      let prof = this.profissionalAlocadoInformado
      return prof != null ? prof.pnc === 'S' : false
    },
    isPnc() {
      let prof = this.profissionalInformado
      return prof != null ? prof.pnc === 'S' : false
    },
    isLancamentoNovo() {
      return !this.event || this.event.id < 0
    },
    isProfissionalPlantaoLiberado() {
      return this.profissionalAlocadoInformado == null || this.profissionalInformado == null || this.idMotivoTroca
    },
    valorTotalCompra1: {
      get: function () {
        return formatarValorNumerico(this.calcularValorTotalCompra())
        /*let val = 0;
        if (this.quantidadeEscala && this.valorCompra) {
          val = formatarValorNumerico(
            (parseFloat(this.quantidadeEscala.toString().replace("R$ ", "").replace(".", "").replace(",", ".")) * parseFloat(this.valorCompra.toString().replace("R$ ", "").replace(".", "").replace(",", ".")))
            + formatarStrParaNumerico(this.valorCompl))
        }
        return val*/
      },
      set: function () {
        //console.log('b')
      }
    },
    pedirAutorizacao() {
      return this.pagamentoAvista === 'S' || formatarStrParaNumerico(this.valorCompl) > 0
    },
    labelAutorizacao() {
      let texto = ''
      if (this.pagamentoAvista === 'S') texto += 'adiantamento'
      if (formatarStrParaNumerico(this.valorCompl)) texto += (texto.length > 0 ? ' e ' : '') + ' complemento'
      return texto.length > 0 ? ('Resp. ' + texto) : ''
    },
  },
  watch: {
    pedirAutorizacao: function (newVal) {
      if (newVal) {
        this.buscarUsuariosAprovadores()
        this.buscarResponsavelPagamento()
      }
    }
  }
};

function formatDate(date, format) {
  if (date !== undefined) {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let string = date.toLocaleDateString("ko-KR", options).replace(/\. /g, "-");
    return string.substr(0, string.length - 1);
  }
}

function dateToStr(date, format) {
  if (date !== undefined && date != null) {
    let strDate = format.replace('yyyy', date.getFullYear())
      .replace('MM', (date.getMonth() + 1).toString().padStart(2, '0'))
      .replace('dd', date.getDate().toString().padStart(2, '0'))
      .replace('HH', date.getHours().toString().padStart(2, '0'))
      .replace('mm', date.getMinutes().toString().padStart(2, '0'))
      .replace('ss', date.getSeconds().toString().padStart(2, '0'));
    return strDate;
  }
}


function formatDateBR(date) {
  // YYYY-MM-DD
  if (date !== undefined) {
    var options = { year: "numeric", month: "2-digit", day: "2-digit" };
    let string = date.toLocaleDateString("pt-BR", options).replace(/\. /g, "-");
    return string.substr(0, string.length);
  }
}

function formatarValorNumerico(valor) {
  const tmp = valor.toString().replace(".", ",").split(",");
  let inteiro = tmp.length > 0 ? tmp[0] : '0';
  let decimal = tmp.length > 1 ? tmp[1].padEnd(2, '0').substr(0, 2) : '00';
  return inteiro + ',' + decimal;
}

function formatarStrParaNumerico(valor) {
  return parseFloat(valor.toString().replace("R$ ", "").replace(".", "").replace(",", "."))
}

</script>

<style lang="scss">

.detalhe-agenda{

  /*.footer-event{
    background-color: rgba(0,0,0,.03);
    border-top: 1px solid rgba(0,0,0,.125);
  }*/

  label, .btn-radio, .label {
    font-size: 14px;    
    color: #3D4147;
    font-weight: 500;
    margin-bottom: 0;
    //text-transform: uppercase;
    //margin-bottom: 5px;
  }

  /*.custom-radio label:after, .custom-radio label:before {
    top: 0;
  }*/

  .modal .modal-content .modal-body{
    line-height: 1.4;
  }

  #input-valor-complementar {
    color: red;
  }
}

//@media (max-width: 768px) {}

.moda-event-plantao .modal-dialog {
  margin: 0;
  max-width: none;  
}

.moda-event-plantao.modal {
  top: 60px;
  left: 3em;
  width: calc(100% - 3em);
}

.moda-event-plantao .modal-dialog, .modal-content {
  height: calc(100% - 60px);
}

.body-event-plantao{
  background-color: #F2F2F2;
}

.footer-event-plantao{
  border-top: 2px solid #3f7bb7;
}

.historico-event-plantao{
  font-size: 11px;
}

</style>