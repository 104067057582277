<template>

    <v-select v-model="itemLocal" @input="$emit('update', itemLocal);" :multiple="multiple" :options="itensLocal"
        @open="buscarLocais" label="descricaoCompleta" ref="selectLocal" :filter="filter" placeholder="Local do plantão">

        <template #selected-option="{ descricao }">
            <div v-bind:style="'font-size:' + (fontSize ? fontSize+'em' : '0.6em') + ';'">
                <span>{{ descricao }} </span>                
            </div>
        </template>

        <template slot="no-options" >
            {{this.carregando ? 'Carregando...' : 'Nenhum local encontrado'}} 
        </template>

        <template slot="option" slot-scope="option">
            <div class="d-center text-wrap"
                v-bind:class="option.nivel == 0 ? 'font-weight-bold' : option.nivel == 1 ? 'font-weight-normal ml-' + option.nivel : 'font-weight-light ml-' + option.nivel">
                {{ option.descricaoCompleta }}
            </div>
        </template>

    </v-select>

</template>
<script>

import AgendaService from "../../service/AgendaService"
import appUtils from '@/composables/utils'

export default {
    props: ["multiple", "fontSize", "hideShowLoading"],
    data() {
        return {
            show: false,
            showFrom: "",
            filaShow: [],
            itemLocal: null,
            itensLocal: [],
            search: '',
            carregando: false,
        }
    },   
    mounted() {
        
    }, 
    methods: {
        async carregaLocais(idLocal) {            
            await this.buscarLocais()            
            this.itemLocal = this.itensLocal.find((o) => o.id === idLocal)            
        },
        async buscarLocais() {
            if (this.itensLocal.length === 0) {
                this.carregando = true
                const { toast, ativarShow, desativarShow } = appUtils(this)

                if(!this.hideShowLoading) ativarShow('buscarLocais')

                let dadosUsuario = JSON.parse(localStorage.getItem("dadosUsuario"));

                let dadosConsulta = {
                    "idUsuario": dadosUsuario.idPessoa,
                    "ativo": 'S',
                    "acima": true
                }

                await AgendaService.listaLocaisFilter(dadosConsulta)
                    .then((resposta) => {
                        this.itensLocal = resposta.data.obj                               
                        if(!this.hideShowLoading) desativarShow('buscarLocais')
                        this.carregando = false
                    })
                    .catch((e) => {
                        if(!this.hideShowLoading) desativarShow('buscarLocais')
                        toast(e, "Falha", "danger");
                        this.carregando = false
                    })
            }

        },
        filterBy(option, label, search) {            

            let itens = options.filter(opt => this.showOpTion(opt, criteria))

            let ids = itens.map(elem => elem.id)
            let idsPai = itens.filter(elem => elem.idPai).map(elem => elem.idPai)
            let idsFilho = ids
            while (true) {

                if (idsFilho.length > 0) {
                    let filhos = options.filter(opt => idsFilho.indexOf(opt.idPai) > -1 && ids.indexOf(opt.id) == -1)
                    idsFilho = filhos.map(elem => elem.id)
                    ids.push.apply(ids, idsFilho)
                }

                if (idsPai.length > 0) {
                    let pais = options.filter(opt => idsPai.indexOf(opt.id) > -1 && ids.indexOf(opt.id) == -1)
                    ids.push.apply(ids, pais.map(elem => elem.id))
                    idsPai = pais.filter(elem => elem.idPai).map(elem => elem.idPai)
                }

                if (idsFilho.length == 0 && idsPai.length == 0) break
            }
            
        },
        existeFilhoNaBusca(option, search) {            
            let ret = false
            let filhos = this.itensLocal.filter((l) => l.idPai && l.idPai === option.id)
            if (filhos) {                             
                for (let i = 0; i < filhos.length; i++) {
                    if ((filhos[i].descricaoCompleta || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) {                        
                        ret = true
                        break
                    } else {
                        ret = this.existeFilhoNaBusca(filhos[i], search)
                        if(ret) break
                    }
                }
            }
            return ret                        
        },
        existePaiNaBusca(option, search) {            
            let ret = false
            let item = this.itensLocal.find((l) => l.id === option.id)
            let pai = this.itensLocal.find((l) => l.id === item.idPai)       
            if (pai) {                                             
                if ((pai.descricaoCompleta || '').toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1) {
                    ret = true
                } else {
                    ret = this.existePaiNaBusca(pai, search)
                }                
            }
            return ret                        
        },
        showOpTion(opt, criteria) {                                    
            const { removerSpecials } = appUtils(this)            
            //return opt.descricaoCompleta.toLowerCase().indexOf(criteria.toLowerCase()) > -1
            return removerSpecials(opt.descricaoCompleta.toLowerCase()).indexOf(removerSpecials(criteria.toLowerCase())) > -1
        },
        filter(options, search) {
            
            let itens = options.filter(opt => this.showOpTion(opt, search))

            let ids = itens.map(elem => elem.id)
            let idsPai = itens.filter(elem => elem.idPai).map(elem => elem.idPai)
            let idsFilho = ids
            while (true) {

                if (idsFilho.length > 0) {
                    let filhos = options.filter(opt => idsFilho.indexOf(opt.idPai) > -1 && ids.indexOf(opt.id) == -1)
                    idsFilho = filhos.map(elem => elem.id)
                    ids.push.apply(ids, idsFilho)
                }

                if (idsPai.length > 0) {
                    let pais = options.filter(opt => idsPai.indexOf(opt.id) > -1 && ids.indexOf(opt.id) == -1)
                    ids.push.apply(ids, pais.map(elem => elem.id))
                    idsPai = pais.filter(elem => elem.idPai).map(elem => elem.idPai)
                }

                if (idsFilho.length == 0 && idsPai.length == 0) break                                        
            }

            return options.filter(opt => ids.indexOf(opt.id) > -1)
            

        
    },
    },
    computed: {
        criteria() {
            return this.search.trim().toLowerCase()
        },
    }

}

</script>
<style>

</style>